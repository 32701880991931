import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import specialistTemplateStyles from "./specialistTemplate.module.scss"
import SEO from "../components/seo"
import { Link } from "gatsby"


export const query = graphql`
  query($slug: String!) {
    wordpressWpUsers(slug: { eq: $slug }) {
      id
      slug
      name
      authored_wordpress__POST {
        title
        date(formatString: "DD MMMM YYYY", locale: "es")
        slug
        status
        featured_media {
          title
          source_url
        }
      }
      specializedin
      description
      mpp_avatar {
        full {
          source_url
          title
        }
      }
    }
  }
`
const SpecialistTemplate = props => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [comment, setComment] = useState("")
  const [submitting, setSubmitting] = useState("")
  const [submitted, setSubmitted] = useState("")
  const [authorVotes, setAuthorVotes] = useState("0")

  const data = props.data.wordpressWpUsers

  function handleSubmit(e) {
    e.preventDefault()
    if (submitting || submitted) {
      return
    }
    setSubmitting(true)
    if (
      name.trim().length < 3 ||
      phone.trim().length < 10 ||
      email.trim().length < 6
    ) {
      setSubmitting(false)
      alert("Completa los campos de manera correcta")
      return
    }
    const fetchData = {
      specialist: data.name,
      name,
      phone,
      email,
    }
    fetch("https://hook.integromat.com/2nmikjbixnjh98vcmok3qykrxym7y871", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(fetchData),
    }).then(response => {
      if (response.ok) {
        setSubmitted(true)
      } else setSubmitted(false)
    })
  }
  useEffect(() => {
    const getData = {
      author: props.data.wordpressWpUsers.id,
    }
    function getVotesCount() {
      fetch("https://unio-mx.herokuapp.com/blogData/getAuthorTotalVotes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(getData),
      }).then(r => {
        if (r.ok) {
          r.json().then(({ votes }) => setAuthorVotes(votes))
        }
      })
    }
    getVotesCount()
  }, [props.data.wordpressWpUsers.id])

  return (
    <>
      <div className={specialistTemplateStyles.container}>
        <SEO
          title={data.name}
          description={data.specializedin}
          url={`/specialists/${data.slug}`}
        />
        <h1 className={specialistTemplateStyles.title}>
          Nuestros Especialistas
        </h1>
        <div className={specialistTemplateStyles.infoContainer}>
          <div className={specialistTemplateStyles.profilePictureContainer}>
            <img
              alt={data.name}
              className={specialistTemplateStyles.profilePicture}
              src={data.mpp_avatar.full.source_url}
            />
          </div>
          <div className={specialistTemplateStyles.specialistData}>
            <div className={specialistTemplateStyles.nameContainer}>
              <h1 className={specialistTemplateStyles.name}>{data.name}</h1>
              <p className={specialistTemplateStyles.specializedin}>
                {data.specializedin}
              </p>
              <p className={specialistTemplateStyles.votes}>
                Recomendaciones: {authorVotes}
              </p>
            </div>
            <h2 className={specialistTemplateStyles.aboutMe}>Acerca de mí</h2>
            <p className={specialistTemplateStyles.description}>
              {data.description}
            </p>
          </div>
        </div>
        <div className={specialistTemplateStyles.blogsContainer}>
          <h2 className={specialistTemplateStyles.subtitle}>Publicaciones</h2>
          <div className={specialistTemplateStyles.blogs}>
            {data.authored_wordpress__POST.map(post => (
              <div className={specialistTemplateStyles.gridItem}>
                <Link
                  to={`/blog/${post.slug}`}
                  title={post.title}
                  className={specialistTemplateStyles.blogpost}
                >
                  {post.featured_media ? (
                    <img
                      className={specialistTemplateStyles.blogImage}
                      src={post.featured_media.source_url}
                      alt={post.title}
                    />
                  ) : (
                    <img
                      className={specialistTemplateStyles.blogImage}
                      src="https://www.uniomx.com/static/ni%C3%B1os-4db7bcedfa4f813de3752dcf15e04716.png"
                      alt={post.title}
                    />
                  )}
                  <h3 className={specialistTemplateStyles.blogTitle}>
                    {post.title.length > 100
                      ? post.title.slice(0, 50) + "..."
                      : post.title}
                  </h3>
                  <h3 className={specialistTemplateStyles.blogDate}>
                    {post.date}
                  </h3>
                </Link>
              </div>
            ))}
          </div>
          <div className={specialistTemplateStyles.contactContainer}>
            <h2 className={specialistTemplateStyles.subtitle}>
              Deja tus datos para ponernos en contacto contigo
            </h2>
            <form
              className={specialistTemplateStyles.form}
              onSubmit={e => handleSubmit(e)}
            >
              <div className={specialistTemplateStyles.namePhoneInput}>
                <div className={specialistTemplateStyles.inputContainer}>
                  <label
                    htmlFor="name"
                    className={specialistTemplateStyles.label}
                  >
                    Nombre completo
                  </label>
                  <input
                    className={specialistTemplateStyles.input}
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="Juan Pérez"
                    autoComplete="name"
                    required
                  />
                </div>
                <div className={specialistTemplateStyles.inputContainer}>
                  <label
                    htmlFor="phone"
                    className={specialistTemplateStyles.label}
                  >
                    Teléfono
                  </label>
                  <input
                    className={specialistTemplateStyles.input}
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    placeholder="55443322"
                    autoComplete="tel"
                    required
                  />
                </div>
              </div>
              <label htmlFor="email" className={specialistTemplateStyles.label}>
                Correo Electrónico
              </label>
              <input
                className={specialistTemplateStyles.input}
                type="email"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="correo@mail.ex"
                autoComplete="email"
                required
              />
              <label htmlFor="email" className={specialistTemplateStyles.label}>
                Comentario
              </label>
              <input
                className={specialistTemplateStyles.input}
                type="text"
                name="comment"
                value={comment}
                onChange={e => setComment(e.target.value)}
                placeholder="Me gustaría que me pudieran ayudar a ......"
              />
              <button
                type="submit"
                disabled={submitting}
                className={specialistTemplateStyles.button}
              >
                {submitted ? "Listo!" : submitting ? "Enviando" : "Enviar"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default SpecialistTemplate
